.canvas-wrapper {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;

    #fluid {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}