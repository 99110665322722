@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* Déclaration de la police ABCFavorit */
@font-face {
  font-family: "ABCFavorit";
  src: url("../fonts/ABCFavorit-Medium-Trial.woff2") format("woff2"),
    url("../fonts/ABCFavorit-Medium-Trial.woff") format("woff"),
    url("../fonts/ABCFavorit-Medium-Trial.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Déclaration de la police MonospaceTypewriter */
@font-face {
  font-family: "MonospaceTypewriter";
  src: url("../fonts/MonospaceTypewriter.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Black */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueBlack.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Bold */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Heavy */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueHeavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Italic */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* Helvetica Neue - Light */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Medium */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Roman */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueRoman.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Helvetica Neue - Thin */
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueThin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@layer base {
  :root {
    --bg: #1c1c1c;
    --color: #e9e8e3;

    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    --shadow-custom: inset 2px 2px 0 0 rgba(255, 255, 255, 0.2),
      inset -2px -2px 0 0 rgba(34, 220, 209, 0.4);

    --scrollbar-foreground: rgba(255, 255, 255, 0.2);
    --scrollbar-background: transparent;
    --scrollbar-radius: 10px;
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

    --on-surface-1: #1a1d1f;
    --on-surface-2: #272b30;
    --on-surface-3: #313639;
    --on-surface: #222628;
    --on-surface-dark: #1a1d1f;
    --on-surface-pure: #1a1d1f;
    --stroke: #272b30;
    --primary: #fcfcfc;
    --primary-fixed: #1a1d1f;
    --secondary: #6f767e;
    --tertiary: #5d6267;
    --white-fixed: #fcfcfc;
    --light: #1a1d1f;
    --n-8: #111315;
    --brand-100: #2a323f;
    --red-100: #6a5550;
    --green-100: rgba(68, 90, 76, 0.57);
    --border-brand: #2a9fff;
    --yellow-100: #957a6c;
    --purple-100: rgba(113, 88, 126, 0.38);
    --purple-300: #a375c3;
    --yellow-50: #82776b;
    --dune: #272c30;
    --green: #32ae60;
    --red: #f04d1a;
    --purple: #b981da;
    --yellow: #fba94b;
    --brand: #0c68e9;
  }
}

@layer components {
  .notification span {
    @apply text-theme-primary;
  }
  .react-tooltip {
    @apply !py-1 !text-[0.8125rem] !leading-[1.25rem] !rounded-lg;
  }
  .range {
    @apply w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:h-1.5 [&::-webkit-slider-runnable-track]:rounded-sm [&::-webkit-slider-runnable-track]:bg-theme-on-surface-2 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-1.5 [&::-webkit-slider-thumb]:-mt-[0.3125rem] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-theme-primary;
  }
  .card-color {
    @apply border border-transparent transition-colors;
  }
  .card-color:nth-child(3n + 1) {
    @apply bg-theme-yellow-50;
  }
  .card-color:nth-child(3n + 1):hover {
    @apply border-theme-yellow;
  }
  .card-color:nth-child(3n + 1) .card-icon-color {
    @apply bg-theme-yellow;
  }
  .card-color:nth-child(3n + 2) {
    @apply bg-theme-purple-100;
  }
  .card-color:nth-child(3n + 2):hover {
    @apply border-theme-purple;
  }
  .card-color:nth-child(3n + 2) .card-icon-color {
    @apply bg-theme-purple;
  }
  .card-color:nth-child(3n + 3) {
    @apply bg-theme-green-100;
  }
  .card-color:nth-child(3n + 3):hover {
    @apply border-theme-green;
  }
  .card-color:nth-child(3n + 3) .card-icon-color {
    @apply bg-theme-green;
  }
  .card-color:nth-child(3n + 3) .card-icon-color {
    @apply bg-theme-green;
  }
  .react-codes-custom .react-codes-input__entered-value span {
    @apply !text-theme-tertiary;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* custom.css */
.gradient-stroke {
  text-shadow: 0px 0px 4px rgba(34, 220, 209, 0.8);
}

.gradient-stroke-less {
  text-shadow: 0px 0px 2px rgba(34, 220, 209, 0.8);
}

.resize-handle {
  position: absolute;
  z-index: 10;
}

.resize-handle-top,
.resize-handle-bottom {
  height: 20px;
  width: 100%;
  left: 0;
  cursor: ns-resize;
}

.resize-handle-top {
  top: 0;
}

.resize-handle-bottom {
  bottom: 0;
}

.resize-handle-left,
.resize-handle-right {
  width: 10px;
  height: 100%;
  top: 0;
  cursor: ew-resize;
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

.resize-handle-top-left,
.resize-handle-top-right,
.resize-handle-bottom-left,
.resize-handle-bottom-right {
  width: 10px;
  height: 10px;
  cursor: nwse-resize;
}

.resize-handle-top-left {
  top: 0;
  left: 0;
  cursor: nwse-resize;
}

.resize-handle-top-right {
  top: 0;
  right: 0;
  cursor: nesw-resize;
}

.resize-handle-bottom-left {
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
}

.resize-handle-bottom-right {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}

.dock-shiny {
  position: relative;
  overflow: hidden;
}

.dock-shiny::after {
  content: "";
  position: absolute;
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  width: 100px;
  height: 100px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 80%
  );
  opacity: 0;
  transition: opacity 0.3s, top 0.3s, left 0.3s;
  pointer-events: none;
  border-radius: 50%;
  z-index: 10;
}

.dock-shiny:hover::after {
  opacity: 0.4;
}

.ant-notification-notice-message {
  @apply text-sm text-white font-helveticaneue tracking-tight-4 gradient-stroke;
}

.ant-notification-notice-description {
  @apply font-helveticaneue font-medium text-sm leading-custom opacity-90;
}

.ant-notification-notice-progress {
}

.ant-notification-notice {
  --tw-backdrop-blur: blur(5px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: var(--scrollbar-radius);
}
*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
  border-radius: var(--scrollbar-radius);
  border: 1 solid transparent !important;
}
*::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
  border-radius: var(--scrollbar-radius);
}

.top-arrow-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333333;
}

.arrow-custom {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}

.animateShow {
  animation: transformDownShow 200ms 1 forwards;
}

@keyframes transformDownShow {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

input[type="range"].ubuntu-slider {
  outline: none;
  -webkit-appearance: none;
  background: linear-gradient(
    to right,
    rgba(175, 175, 175, 0.3) 0%,
    rgba(175, 175, 175, 0.3) 100%
  );
  background-position: center;
  background-size: 99% 3px;
  background-repeat: no-repeat;
  /* width: 65%; */
  height: 6px;
  border-radius: 50%;
}

input[type="range"].ubuntu-slider::-webkit-slider-thumb {
  -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: #fff;
  pointer-events: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
}

/* Window's styling */
.arrow-custom-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  width: 0;
}

.arrow-custom-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  width: 0;
}

.arrow-custom-left {
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-right: 5px solid white;
  width: 0;
}

.arrow-custom-right {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid white;
  width: 0;
}

.window-y-border {
  height: calc(100% - 10px);
  width: calc(100% + 10px);
  cursor: e-resize;
}

.window-x-border {
  height: calc(100% + 10px);
  width: calc(100% - 10px);
  cursor: n-resize;
}

.notFocused {
  filter: brightness(90%);
}

.root,
#root,
#docs-root {
  --window-transform-x: 0px;
  --window-transform-y: 0px;
}

.window-shadow {
  box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
}

.closed-window {
  animation: closeWindow 200ms 1 forwards;
}

@keyframes closeWindow {
  0% {
    opacity: 1;
    transform: translate(var(--window-transform-x), var(--window-transform-y))
      scale(1);
    visibility: visible;
  }

  100% {
    opacity: 0;
    transform: translate(var(--window-transform-x), var(--window-transform-y))
      scale(0.85);
    visibility: hidden;
  }
}

.windowMainScreen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.windowMainScreen::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.windowMainScreen::-webkit-scrollbar-thumb {
  background-color: #d3d7cf;
  border-radius: 5px;
}

/* SideBarApp Scale image onClick */
.scalable-app-icon {
  visibility: hidden;
}

.scalable-app-icon.scale {
  animation: scaleAppImage 400ms 1 forwards;
}

@keyframes scaleAppImage {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
  }

  to {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
    visibility: hidden;
  }
}

/* Context Menu */
.context-menu-bg {
  background-color: rgb(43, 43, 43);
}

.emoji-list > li {
  padding-left: 10px;
}

.list-pc {
  list-style: "🏫";
}

.list-building {
  list-style: "👨🏻‍💻";
}

.list-time {
  list-style: "🎲";
}

.list-star {
  list-style: "🌟";
}

.list-arrow {
  list-style: "⇀";
}

.noVNC_container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.react-joyride__tooltip span:focus-visible {
  outline: none !important;
}

/* Ou de manière plus générale */
.react-joyride__tooltip :focus-visible {
  outline: none !important;
}

.react-tel-input .selected-flag:hover {
  background-color: var(--on-surface) !important;
}

.react-tel-input .selected-flag:hover {
  background-color: var(--on-surface) !important;
}

.react-tel-input .country-list .country:hover {
  background-color: var(--on-surface) !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: var(--on-surface) !important;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
