.btn {
    padding: 1.2rem 2rem;
    width: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s linear;
    color: $primary;

    span {
        mix-blend-mode: difference;
        font-size: 1rem;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid $primary;
        border-radius: 50px;
        transition: all .2s linear;
    }

    &:hover {
        &::before {
            height: 80px;
            width: 80px;
            background: $primary;
            filter: blur(10px);
            scale: 2;
            border-radius: 50%;
        }
    }
}