
@font-face {
    font-family: syneM;
    src: url(../../fonts/Syne-Medium.ttf);
}

@font-face {
    font-family: syneR;
    src: url(../../fonts/Syne-Regular.ttf);
}

.heading {
    position: relative;
    line-height: 90%;

    .fade {
        opacity: 0.2;
        position: relative;
    }

    .fill {
        opacity: 1;
        color: $primary;
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        overflow: hidden;
    }
}

.intro_h1 {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    line-height: 90%;
}

// h2 {
//     font-size: 6rem;
//     font-family: dirtyline;
//     font-weight: lighter;
//     text-transform: lowercase;
//     line-height: 90%;
// }

// h3 {
//     font-size: 4rem;
//     font-family: dirtyline;
//     font-weight: lighter;
//     text-transform: lowercase;
//     line-height: 110%;
// }

// h4 {
//     font-size: 2.2rem;
//     font-family: dirtyline;
//     font-weight: lighter;
//     text-transform: lowercase;
//     line-height: 90%;
// }

// h5 {
//     font-size: 2rem;
//     font-weight: lighter;
//     line-height: 120%;
// }

// p {
//     font-size: 1.1rem;
//     line-height: 140%;
// }

.smallT {
    font-size: 0.8rem;
    line-height: 140%;
}

//====================================== Breakpoints

@media (min-width: 1800px) {
    .smallT {
        font-size: 1rem;
    }

    p {
        font-size: 1.4rem;
    }

    h1 {
        font-size: 10rem;
    }
}

@media (max-width: 1440px) {
    h1 {
        font-size: 7rem;
    }

    h2 {
        font-size: 5rem;
    }

    h5 {
        font-size: 1.8rem;
    }
}

@media (max-width: 780px) {
    h1 {
        font-size: 6rem;
    }

    h2 {
        font-size: 4rem;
    }

    p {
        font-size: 1.4rem;
    }

    .smallT {
        font-size: 1.2rem;
    }
}

@media (max-width: 520px) {
    h1 {
        font-size: 20vw;
    }

    h2 {
        font-size: 15vw;
    }

    h3 {
        font-size: 10vw;
    }

    h4 {
        font-size: 8vw;
    }

    h5 {
        font-size: 6vw;
    }

    p {
        font-size: 1rem;
    }

    .smallT {
        font-size: 0.8rem;
    }
}
